import React from 'react';

const Footer = () => {
  return (
    <footer className='footer'>
      <p>&copy; {new Date().getFullYear()} code and illustrations by jona. </p>
    </footer>
  ); 
};



export default Footer;
